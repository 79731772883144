import Vue from "vue";
import VueRouter from "vue-router";
import { userDataGet } from "@/components-js/requestSrv";

Vue.use(VueRouter);

// all routes in application

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Start.vue"),
  },
  {
    path: "/today",
    name: "Today",
    component: () => import("@/views/Today.vue"),
    meta: { roles: ["user", "admin"], showMenu: true },
  },
  {
    path: "/schedule",
    name: "schedule",
    component: () => import("@/views/SchedulePage"),
    meta: { roles: ["user", "admin"], showMenu: true, isNewDesign: true },
    children: [
      {
        path: "settings",
        name: "schedule-settings",
        component: () => import("@/components/TableFilters"),
        meta: { roles: ["user", "admin", "pointadmin"], showMenu: true, isNewDesign: true },
        children: [
          {
            path: "tags",
            name: "schedule-tags-settings",
            component: () => import("@/views/SchedulePage/components/ShiftTagsSetupModal"),
            meta: { roles: ["user", "admin", "pointadmin"], showMenu: true, isNewDesign: true },
          },
          {
            path: "shift",
            name: "schedule-shift-settings",
            component: () => import("@/views/SchedulePage/components/ShiftEditModal"),
            meta: { roles: ["user", "admin", "pointadmin"], showMenu: true, isNewDesign: true },
          },
        ],
      },
      {
        name: "personal-schedule-modal",
        path: "personal",
        component: () => import("@/components/ScheduleModal"),
        meta: { roles: ["user", "admin", "pointadmin"], showMenu: true, isNewDesign: true },
      },
    ],
  },
  {
    path: "/chesstable",
    name: "Chesstable",
    component: () => import("@/views/ChessTable.vue"),
    meta: { roles: ["user", "admin"], showMenu: true },
  },
  {
    path: "/freeturns",
    name: "FreeTurnsUsr",
    component: () => import("@/views/FreeShiftListPage"),
    meta: { roles: ["user", "admin"], showMenu: true, isNewDesign: true },
  },
  {
    path: "/freeturns-admin",
    name: "FreeTurnsAdm",
    component: () => import("@/views/FreeShiftListPage"),
    meta: { roles: ["admin"], showMenu: true, paidFeature: true, isNewDesign: true },
  },
  {
    path: "/personal",
    name: "personal",
    component: () => import("@/views/ShiftListPage"),
    meta: { roles: ["user", "admin", "pointadmin"], showMenu: true, isNewDesign: true },
    children: [
      {
        name: "personal-settings",
        path: "settings",
        component: () => import("@/components/PersonalTableFilters"),
        meta: { roles: ["user", "admin", "pointadmin"], showMenu: true, isNewDesign: true },
      },
      {
        name: "personal-chesstable-modal",
        path: "chesstable",
        component: () => import("@/components/ScheduleModal"),
        meta: { roles: ["user", "admin", "pointadmin"], showMenu: true, isNewDesign: true },
      },
    ],
  },
  // {
  //   path: "/personal",
  //   name: "PersonalTable",
  //   component: () => import("@/views/Personal.vue"),
  //   meta: { roles: ["user", "admin"], showMenu: true },
  // },
  {
    path: "/salary",
    name: "Salary",
    component: () => import("@/views/Salary.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/salarybypandp",
    name: "Salarybypandp",
    component: () => import("@/views/SalaryByPandP.vue"),
    meta: { roles: ["admin"], showMenu: true, paidFeature: true },
  },
  {
    path: "/summary",
    name: "Summary",
    component: () => import("@/views/Summary.vue"),
    meta: { roles: ["admin"], showMenu: true, paidFeature: true },
  },
  {
    path: "/reports",
    name: "ReportList",
    component: () => import("@/views/Reports.vue"),
    meta: { roles: ["admin", "pointadmin"], showMenu: true },
  },
  {
    path: "/peoplelist",
    name: "PeopleList",
    component: () => import("@/views/PeopleViewHolder.vue"),
    meta: { roles: ["admin", "hradmin", "user"], showMenu: true, paidFeature: false },
    children: [
      {
        name: "people-list-edit",
        path: "edit",
        meta: { roles: ["admin", "hradmin", "user"], showMenu: true, paidFeature: false },
        component: () => import("@/components/PeopleWindow/PeopleWindow.vue"),
      },
    ],
  },
  // {
  //   path: "/peoplelistsimple",
  //   name: "PeopleListSimple",
  //   component: () => import("@/views/PeopleListSimple.vue"),
  //   meta: { roles: ["user"], showMenu: true },
  // },
  {
    path: "/logs",
    name: "Logs",
    component: () => import("@/views/Logs.vue"),
    meta: { roles: ["admin"], showMenu: true, paidFeature: true },
  },
  {
    path: "/wiki",
    name: "Wiki",
    beforeEnter() {
      window.open("https://buildin.ai/brew/share/b507f2c3-8616-49a6-8348-a9e44d7c22b2?code=EVML3D", "_top");
    },
    meta: { roles: ["user", "admin"], showMenu: true, isBrew: true },
  },
  {
    path: "/hrWiki",
    name: "HrWiki",
    beforeEnter() {
      window.open("https://brew.yonote.ru/share/brew_wiki", "_top");
    },
    meta: { roles: ["user", "admin"], showMenu: true, isBrew: true },
  },
  {
    path: "/adminsWiki",
    name: "AdminsWiki",
    beforeEnter() {
      window.open("https://brew.yonote.ru/share/brew_wiki", "_top");
    },
    meta: { roles: ["user", "admin"], showMenu: true, isBrew: true },
  },
  {
    path: "/freeturns",
    name: "FreeTurns",
    component: () => import("@/views/FreeShiftListPage"),
    meta: { roles: ["admin"], showMenu: true, isNewDesign: true },
  },
  {
    path: "/freeturns-old",
    name: "FreeTurnsOld",
    component: () => import("@/views/FreeTurns.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/othersusr",
    name: "OthersUsr",
    component: () => import("@/views/OthersUser.vue"),
    meta: { roles: ["user"], showMenu: true },
  },
  {
    path: "/othersadm",
    name: "OthersAdm",
    component: () => import("@/views/OthersAdmin.vue"),
    meta: { roles: ["admin"], showMenu: true, paidFeature: true },
  },
  {
    path: "/checkMenu",
    name: "CheckMenu",
    component: () => import("@/views/CheckMenu.vue"),
    meta: { roles: ["admin"], showMenu: true, paidFeature: true },
  },
  {
    path: "/chesstabledraft",
    name: "Manual",
    component: () => import("@/views/ChessTableDraft.vue"),
    meta: { roles: ["admin"], showMenu: true, paidFeature: true },
  },
  {
    path: "/auto",
    name: "Auto",
    component: () => import("@/views/Auto.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/salesin",
    name: "Salesin",
    component: () => import("@/views/Salesin.vue"),
    meta: { roles: ["admin"], showMenu: true, paidFeature: true },
  },
  {
    path: "/statsfunny",
    name: "StatsFunny",
    component: () => import("@/views/StatsFunny.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/tomorrow",
    name: "Tomorrow",
    component: () => import("@/views/Tomorrow.vue"),
    meta: { roles: ["user", "admin"], showMenu: true },
  },
  {
    path: "/yesterday",
    name: "Yesterday",
    component: () => import("@/views/Yesterday.vue"),
    meta: { roles: ["user", "admin"], showMenu: true },
  },
  {
    path: "/reference",
    name: "Reference",
    component: () => import("@/views/Reference.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/pointlist",
    name: "PointList",
    component: () => import("@/views/PointList.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/joblist",
    name: "JobList",
    component: () => import("@/views/JobList.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/eventlist",
    name: "EventList",
    component: () => import("@/views/EventList.vue"),
    meta: { roles: ["admin"], showMenu: true, paidFeature: true },
  },
  {
    path: "/eventcalendar",
    name: "EventCalendar",
    component: () => import("@/views/EventCalendar.vue"),
    meta: { roles: ["admin", "user"], showMenu: true },
  },
  {
    path: "/bariskahistory",
    name: "BariskaHistory",
    component: () => import("@/views/BariskaHistory.vue"),
    meta: { roles: ["admin", "hradmin"], showMenu: true, paidFeature: true },
  },
  {
    path: "/moneymovementsheet",
    name: "MoneyMovementSheet",
    component: () => import("@/views/MoneyMovementSheet.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/salaryaverage",
    name: "SalaryAverage",
    component: () => import("@/views/SalaryAverage.vue"),
    meta: { roles: ["admin"], showMenu: true, paidFeature: true },
  },
  {
    path: "/moneymovementsimport",
    name: "MoneyMovementsImport",
    component: () => import("@/views/MoneyMovementsImport.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/moneypersonal",
    name: "MoneyPersonal",
    component: () => import("@/views/MoneyPersonal.vue"),
    meta: { roles: ["user"], showMenu: true },
  },
  {
    path: "/salaryhourly",
    name: "SalaryHourly",
    component: () => import("@/views/SalaryHourly.vue"),
    meta: { roles: ["admin"], showMenu: true, paidFeature: true },
  },
  {
    path: "/mybusiness",
    name: "MyBusiness",
    component: () => import("@/views/MyBusiness.vue"),
    meta: { roles: ["user", "admin"], showMenu: true },
  },

  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/views/Logout.vue"),
  },
  {
    path: "/*",
    name: "NotFound",
    component: { render: h => h("div", ["Page Not Found"]) },
    meta: { needAuth: false, showMenu: true },
  },
];

const router = new VueRouter({
  // TODO раскомментить и настроить нормально
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // continue session, if token is still alive
  if (to.fullPath === "/") next({ name: "Today" });

  // if access check required
  if (to.meta.roles) {
    const user = await userDataGet();
    if (to.fullPath === "/wiki" && !user.brew) {
      next({ name: "Today" });
    }
    if (user?.roles && user?.roles.map(ur => to.meta.roles.includes(ur)).includes(true)) {
      if (!!to.meta.paidFeature && !user.brew) {
        next({ name: "Today" });
      } else {
        // access grant
        next();
      }
    } else {
      // if access denied, clear all
      // rolesClear();
      next({ name: "Login" });
    }
  }
  // welcome to public route
  next();
});

export default router;
